import axios from 'axios';
import { destroyCookie } from 'nookies';
import { AuthTokenError } from './errors/AuthTokenErrors';

// const url = 'http://localhost:5003/';
const url = 'https://fauvel-moraes-server-production.up.railway.app//';

export function setupAPIClient(ctx = undefined) {
  
  const authToken = localStorage.getItem('augustoFauvel');

  const api = axios.create({
    baseURL: url,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response.status === 401) {
        if (typeof window !== 'undefined') {
          try {
            // Importe a função de logout do contexto de autenticação
            // const { logout } = require('../contexts/AuthContext');
            // await logout();
          } catch (e) {
            console.error('Erro ao fazer logout:', e);
          }
        } else {
          return Promise.reject(new AuthTokenError());
        }
      }
      return Promise.reject(error);
    }
  );
  
  return api;
}

export function destroyAPIClient() {
  destroyCookie(undefined, '@augustoFauvel.token'); // Remova o token do cookie ao destruir o cliente da API
}
