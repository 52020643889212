import React, { useState, useEffect } from 'react';
import { Col, Row, Table, Spin, Modal, Button, Input } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import styles from './style.module.scss';
import { api } from '../../services/apiClient';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const DashboardIcms = () => {
  const [loading, setLoading] = useState(true);
  const [clientes, setClientes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [size, setSize] = useState('large');
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const [selectedCliente, setSelectedCliente] = useState(null);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await api.get('/icms');
        console.log('response', response);
        setClientes(response.data);
        setLoading(false);
      } catch (error) {
        toast.error('Erro ao buscar clientes.');
        setLoading(false);
      }
    };

    fetchClientes();
  }, []);

  const handlePreview = (url) => {
    setPreviewImage(url);
    setIsModalOpen(true);
  };

  const handleDownloadClick = (record) => {
    setSelectedCliente(record);
    setSecondModalOpen(true);
  };

  const generatePDF = () => {
    const modalContent = document.getElementById('second-modal-content');
    html2canvas(modalContent, { scale: 10 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(imgData, 'JPEG', 10, 10, 190, 120);
      pdf.save('cliente.pdf');
    });
  };

  const columns = [
    {
      title: 'Nome Completo',
      dataIndex: 'nomeCliente',
      key: 'nomeCliente',
      className: styles.columnEllipsis,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      className: styles.columnEllipsis,
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      key: 'telefone',
      className: styles.columnEllipsis,
    },
    {
      title: 'CPF/CNPJ',
      dataIndex: 'cpfCnpj',
      key: 'cpfCnpj',
      className: styles.columnEllipsis,
    },
    {
      title: 'Download',
      key: 'downloadDocumento',
      render: (_, record) => (
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          size={size}
          onClick={() => handleDownloadClick(record)}
        />
      ),
    },
  ];

  return (
    <div className={`${styles.container} ${styles.tableContainer}`}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Spin spinning={loading}>
            <Table dataSource={clientes} columns={columns} rowKey="id" />
          </Spin>
        </Col>
      </Row>
      <Modal
        open={isModalOpen}
        title="Visualizar Documento"
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        <img alt="document" style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <Modal
        open={secondModalOpen}
        title="Resumo das Informações"
        onCancel={() => setSecondModalOpen(false)}
        footer={[
          <Button key="back" onClick={() => setSecondModalOpen(false)}>
            Fechar
          </Button>,
          <Button key="generatePDF" type="primary" onClick={generatePDF}>
            Gerar PDF
          </Button>,
        ]}
      >
        {selectedCliente && (
          <div id="second-modal-content">
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Nome Completo:</label>
                <Input value={selectedCliente.nomeCliente} />
              </Col>
              <Col span={12}>
                <label>CPF/CNPJ:</label>
                <Input value={selectedCliente.cpfCnpj} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Email:</label>
                <Input value={selectedCliente.email} />
              </Col>
              <Col span={12}>
                <label>Telefone:</label>
                <Input value={selectedCliente.telefone} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Insumos no Processo Produtivo:</label>
                <Input value={selectedCliente.insumosProcessoProdutivo} />
              </Col>
              <Col span={12}>
                <label>Insumos no Produto Final:</label>
                <Input value={selectedCliente.insumosProdutoFinal} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Insumos Consumidos/Desgastados:</label>
                <Input value={selectedCliente.insumosConsumidosDesgastados} />
              </Col>
              <Col span={12}>
                <label>Insumos Indispensáveis:</label>
                <Input value={selectedCliente.insumosIndispensaveis} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Insumos para Transporte/Industrialização:</label>
                <Input value={selectedCliente.insumosTransporteIndustrializacao} />
              </Col>
              <Col span={12}>
                <label>Documentação Comprovante:</label>
                <Input value={selectedCliente.documentacaoComprovante} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Insumos Intermediários/Secundários:</label>
                <Input value={selectedCliente.insumosIntermediariosSecundarios} />
              </Col>
              <Col span={12}>
                <label>Insumos Consumidos Integralmente:</label>
                <Input value={selectedCliente.insumosConsumidosIntegralmente} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <label>Insumos em Conformidade com Normas:</label>
                <Input value={selectedCliente.insumosConformeNormas} />
              </Col>
              <Col span={12}>
                <label>Consulta à Legislação:</label>
                <Input value={selectedCliente.consultaLegislacao} />
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default DashboardIcms;
