import React from 'react';
import { Layout, Menu, Avatar, Button, Divider } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { IoClipboardOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

import styles from './style.module.scss';
import { useMenuData } from '../../menuData'; // Certifique-se de que o caminho está correto

const { Sider } = Layout;

const SidebarOfficial = ({ collapsed, toggleCollapsed }) => {
  const { user, logout } = useAuth();
  const menuData = useMenuData(); // Usando o hook customizado para obter os dados do menu

  const handleLogout = () => {
    logout(); // Lógica de logout
  };

  const renderMenuItem = (item) => (
    <Menu.Item key={item.key} icon={item.icon} className={styles.leftAlign}>
      {item.to ? <Link to={item.to}>{item.title}</Link> : item.title}
    </Menu.Item>
  );

  const renderSubMenu = (submenu) => {
    // Filtrar itens do submenu com base no nível de usuário
    const visibleChildren = submenu.children.filter((child) => child.isVisible);

    // Se o submenu não deve ser visível ou todos os seus itens forem filtrados, não renderiza o submenu
    // if (!submenu.isVisible || user.tipoUsuario > submenu.requiredUserLevel || visibleChildren.length === 0) {
    //   return null;
    // }

    return (
      <Menu.SubMenu key={submenu.key} icon={submenu.icon} title={submenu.title} className={styles.leftAlign}>
        {visibleChildren.map((child) => renderMenuItem(child))}
      </Menu.SubMenu>
    );
  };

  const renderMenuItems = () => {
    const filteredItems = menuData.filter((item) => item.isVisible);

    return filteredItems.flatMap((item, index) => {
      const menuItem = item.children ? renderSubMenu(item) : renderMenuItem(item);
      const divider = item.hasDividerBefore ? <Divider key={`divider-${index}`} /> : null;
      return divider ? [divider, menuItem] : menuItem;
    });
  };

  return (
    <Sider width={256} collapsible reverseArrow={false} collapsed={collapsed} onCollapse={toggleCollapsed} breakpoint='md' collapsedWidth='0' theme='light' className={styles.sidebar}>
      <Menu inlineCollapsed={true} theme='light' mode='inline' defaultSelectedKeys={['dashboard']} style={{ minHeight: '90vh', overflowX: 'auto', borderRight: 0, backgroundColor: '#FFF9E2' }}>
        {renderMenuItems()}
      </Menu>
      <div className={styles.sidebarFooter}>
        <Button icon={<UserOutlined />} type='text' onClick={handleLogout} className={styles.button}>
          Sair da conta
        </Button>
      </div>
    </Sider>
  );
};

export default SidebarOfficial;
