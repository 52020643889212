import React, { useState } from 'react';
import { Steps, Button, Form, Input, Radio, Row, Col, message } from 'antd';
import InputMask from 'react-input-mask';
import styles from './style.module.scss';
import bg from '../../assets/bg.png';
import { api } from '../../services/apiClient';

const { Step } = Steps;

const Icms = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [success, setSuccess] = useState(false);

  const [formData, setFormData] = useState({
    nomeCliente: '',
    telefone: '',
    email: '',

    cpfCnpj: '',
    insumosProcessoProdutivo: '',
    insumosProdutoFinal: '',
    insumosConsumidosDesgastados: '',
    insumosIndispensaveis: '',
    insumosTransporteIndustrializacao: '',
    documentacaoComprovante: '',
    insumosIntermediariosSecundarios: '',
    insumosConsumidosIntegralmente: '',
    insumosConformeNormas: '',
    consultaLegislacao: '',
  });

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const onSubmit = async () => {
    // message.success('Formulário enviado com sucesso!');
    console.log(formData);

    // setLoading(true);
    try {
      const dataToSend = { ...formData };
      await api.post('/icms', dataToSend);
      message.success('Cadastro realizado com sucesso!');
      setSuccess(true);
    } catch (error) {
      message.error('Erro ao realizar o cadastro.');
    }
    // setLoading(false);
  };

  const onChangeValue = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.augustofoto02}>
        <img src={bg} alt='augusto' />
      </div>
      <div className={styles.containerFormulario}>
        <div className={styles.steps}>
          <Steps current={currentStep} direction='horizontal'>
            {Array.from({ length: 6 }, (_, i) => ({ title: '' })).map((item, index) => (
              <Step key={index} />
            ))}
          </Steps>
        </div>

        <Form form={form} layout='vertical' autoComplete='off' style={{ width: '100%' }}>
          <div className={styles.stepsContent}>
            {currentStep === 0 && (
              <>
                <h2>Preencha os dados do cliente</h2>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item label='Nome Completo' name='nomeCliente' rules={[{ required: true, message: 'Por favor, insira seu nome completo' }]}>
                      <Input onChange={(e) => onChangeValue('nomeCliente', e.target.value)} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Form.Item label='Telefone' name='telefone' rules={[{ required: true, message: 'Por favor, insira seu telefone' }]}>
                      <InputMask mask='(99) 99999-9999' maskChar={null} value={formData.telefone} onChange={(e) => onChangeValue('telefone', e.target.value)}>
                        {(inputProps) => <Input {...inputProps} />}
                      </InputMask>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item label='CPF/CNPJ' name='cpfCnpj' rules={[{ required: true, message: 'Por favor, insira seu CPF ou CNPJ' }]}>
                      <InputMask mask={formData.cpfCnpj.length <= 14 ? '999.999.999-99' : '99.999.999/9999-99'} maskChar={null} value={formData.cpfCnpj} onChange={(e) => onChangeValue('cpfCnpj', e.target.value)}>
                        {(inputProps) => <Input {...inputProps} />}
                      </InputMask>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Form.Item label='E-mail' name='email' rules={[{ required: true, message: 'Por favor, insira seu e-mail' }]}>
                      <Input type='email' onChange={(e) => onChangeValue('email', e.target.value)} />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {currentStep === 1 && (
              <>
                <h2>Questionário sobre o direito ao aproveitamento de créditos de ICMS sobre insumos</h2>
                <Form.Item label='Os insumos adquiridos são utilizados diretamente no processo produtivo?'>
                  <Radio.Group onChange={(e) => onChangeValue('insumosProcessoProdutivo', e.target.value)} value={formData.insumosProcessoProdutivo}>
                    <Radio value='sim'>Sim</Radio>
                    <Radio value='nao'>Não</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label='Os insumos adquiridos integram fisicamente o produto final?'>
                  <Radio.Group onChange={(e) => onChangeValue('insumosProdutoFinal', e.target.value)} value={formData.insumosProdutoFinal}>
                    <Radio value='sim'>Sim</Radio>
                    <Radio value='nao'>Não</Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            {currentStep === 2 && (
              <>
                <Form.Item label='Os insumos são consumidos ou desgastados gradativamente no processo produtivo?'>
                  <Radio.Group onChange={(e) => onChangeValue('insumosConsumidosDesgastados', e.target.value)} value={formData.insumosConsumidosDesgastados}>
                    <Radio value='sim'>Sim</Radio>
                    <Radio value='nao'>Não</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label='Os insumos são indispensáveis para a realização da atividade-fim da empresa?'>
                  <Radio.Group onChange={(e) => onChangeValue('insumosIndispensaveis', e.target.value)} value={formData.insumosIndispensaveis}>
                    <Radio value='sim'>Sim</Radio>
                    <Radio value='nao'>Não</Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            {currentStep === 3 && (
              <>
                <Form.Item label='Os insumos adquiridos são utilizados em atividades de transporte ou industrialização?'>
                  <Radio.Group onChange={(e) => onChangeValue('insumosTransporteIndustrializacao', e.target.value)} value={formData.insumosTransporteIndustrializacao}>
                    <Radio value='sim'>Sim</Radio>
                    <Radio value='nao'>Não</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label='A empresa possui documentação que comprove a necessidade dos insumos para o processo produtivo?'>
                  <Radio.Group onChange={(e) => onChangeValue('documentacaoComprovante', e.target.value)} value={formData.documentacaoComprovante}>
                    <Radio value='sim'>Sim</Radio>
                    <Radio value='nao'>Não</Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            {currentStep === 4 && (
              <>
                <Form.Item label='Os insumos adquiridos são considerados produtos intermediários ou secundários?'>
                  <Radio.Group onChange={(e) => onChangeValue('insumosIntermediariosSecundarios', e.target.value)} value={formData.insumosIntermediariosSecundarios}>
                    <Radio value='intermediarios'>Produtos intermediários</Radio>
                    <Radio value='secundarios'>Produtos secundários</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label='Os insumos adquiridos são consumidos imediata e integralmente no processo de industrialização?'>
                  <Radio.Group onChange={(e) => onChangeValue('insumosConsumidosIntegralmente', e.target.value)} value={formData.insumosConsumidosIntegralmente}>
                    <Radio value='sim'>Sim</Radio>
                    <Radio value='nao'>Não</Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            {currentStep === 5 && !success && (
              <>
                <h2>Parecer Jurídico</h2>

                <h3>I. Contexto e Questão Jurídica</h3>
                <p>
                  No final de 2023, o Superior Tribunal de Justiça (STJ) decidiu que é possível o creditamento de ICMS sobre a aquisição de produtos intermediários utilizados no processo produtivo, mesmo aqueles consumidos ou desgastados
                  gradativamente, desde que seja comprovada sua necessidade para a realização do objeto social da empresa — ou seja, sua essencialidade para a atividade-fim. (EAREsp n. 1.775.781/SP, relatora Ministra Regina Helena Costa, Primeira
                  Seção, julgado em 11/10/2023, DJe de 1/12/2023).
                </p>
                <p>A questão central é se insumos intermediários, que não se integram diretamente ao produto final, mas são essenciais ao processo produtivo, podem gerar créditos de ICMS.</p>

                <h3>II. Análise da Legislação</h3>
                <p>
                  <strong>Legislação Anterior (Convênio ICMS 66/88):</strong> Antes da Lei Complementar nº 87/96 (Lei Kandir), apenas os insumos que integravam o produto final ou fossem consumidos de forma integral e imediata no processo produtivo
                  geravam créditos de ICMS.
                </p>
                <p>
                  <strong>Legislação Atual (Lei Complementar nº 87/96):</strong> A Lei Kandir ampliou as condições para o creditamento de ICMS, permitindo a apropriação de créditos para insumos utilizados no processo produtivo, mesmo que não integrem
                  o produto final, desde que sejam essenciais para a atividade econômica do contribuinte.
                </p>

                <h3>III. Divergência e Decisão do STJ</h3>
                <p>
                  A Primeira Seção do STJ decidiu, de forma unânime, que a legislação atual, conforme a Lei Kandir, permite o creditamento de ICMS sobre insumos intermediários que, embora não se integrem ao produto final, são necessários para a
                  atividade-fim da empresa. Essa decisão supera o entendimento anterior, que exigia a integração ao produto final como requisito para o crédito.
                </p>

                <h3>IV. Justificativa da Decisão</h3>
                <p>
                  <strong>Necessidade e Pertinência:</strong> O STJ destacou que o direito ao crédito de ICMS não está mais condicionado à integração imediata do insumo ao produto final, mas sim à sua utilização relevante no processo produtivo. Essa
                  interpretação está alinhada com o princípio da não cumulatividade do ICMS, garantindo que os créditos sejam apropriados de forma justa e proporcional ao uso do insumo.
                </p>
                <p>
                  <strong>Impacto da Decisão:</strong> A decisão esclarece e uniformiza o entendimento sobre o creditamento de ICMS, oferecendo maior segurança jurídica aos contribuintes e limitando a aplicação de critérios ultrapassados do Convênio
                  ICMS 66/88.
                </p>

                <h3>V. Recomendações</h3>
                <p>
                  É essencial que os contribuintes realizem um levantamento detalhado e a documentação dos insumos intermediários utilizados em seus processos produtivos. Esses registros são fundamentais para comprovar a essencialidade dos insumos à
                  atividade econômica e garantir o aproveitamento adequado dos créditos de ICMS.
                </p>

                <h3>VI. Conclusão</h3>
                <p>
                  A recente decisão do STJ representa um avanço importante na interpretação das regras de creditamento de ICMS, alinhando a jurisprudência ao texto da Lei Complementar nº 87/96 e garantindo um tratamento mais justo para os
                  contribuintes. Esse precedente deve ser observado por todos que gerenciam créditos de ICMS, podendo influenciar práticas fiscais futuras.
                </p>
                <p>
                  Para que o crédito seja permitido, é necessário comprovar que os insumos são essenciais para a realização do objeto social da empresa, incluindo aqueles que não integram o produto final, mas são indispensáveis ao processo produtivo.
                </p>
              </>
            )}
            {success && (
              <div className={styles.successMessage}>
                <h2>Cadastro realizado com sucesso!</h2>
                <p>Entraremos em contato em breve.</p>
              </div>
            )}
          </div>
          {!success && (
            <div className='steps-action'>
              {currentStep < 5 && (
                <Button className={styles.buttonNextStep} onClick={nextStep}>
                  Próximo
                </Button>
              )}
              {currentStep === 5 && (
                <Button className={styles.buttonNextStep} onClick={onSubmit}>
                  Enviar
                </Button>
              )}
              {currentStep > 0 && (
                <Button className={styles.buttonback} onClick={prevStep}>
                  Voltar
                </Button>
              )}
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default Icms;
