import React, { useState } from 'react';
import { Steps, Button, Form, Input, Radio, Col, Row, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import bg from '../../assets/bg.png';
import { IoChevronBack } from 'react-icons/io5';
import { GoPlus } from 'react-icons/go';
import { IoMdCopy } from 'react-icons/io';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { api } from '../../services/apiClient';

const { Step } = Steps;
const { Dragger } = Upload;

const CadastroCliente = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    email: '',
    isPresencial: false,
    nomeCliente: '',
    cep: '',
    logradouro: '',
    bairro: '',
    cidade: '',
    estado: '',
    numero: '',
    telefone: '',
    cpfCnpj: '',
    assuntoConsulta: '',
    urlDocumentos: [],
    etapa: '',
  });
  const [success, setSuccess] = useState(false);

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const urls = await Promise.all(fileList.map((file) => uploadFile(file.originFileObj)));
      const dataToSend = { ...formData, urlDocumentos: urls };
      await api.post('/consulta', dataToSend);
      message.success('Cadastro realizado com sucesso!');
      setSuccess(true);
    } catch (error) {
      message.error('Erro ao realizar o cadastro.');
    }
    setLoading(false);
  };

  const onChangeValue = (e) => {
    setFormData({
      ...formData,
      isPresencial: e.target.value === 'presencial',
    });
  };

  const props = {
    name: 'file',
    multiple: true,
    fileList,
    beforeUpload: () => false,
    onChange(info) {
      setFileList(info.fileList);
    },
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await api.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data.data.Key;
    } catch (error) {
      console.error('Erro ao fazer upload de arquivo', error);
      throw error;
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText('09514034000179');
      toast.success('Código copiado com sucesso!!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (err) {
      console.error('Falha ao copiar.', err);
    }
  };

  const handleCepChange = async (e) => {
    const valor = e.target.value.replace(/\D/g, ''); // Remove tudo que não for dígito
    setFormData({
      ...formData,
      cep: valor,
    });
    if (valor.length === 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${valor}/json/`);
        setFormData({
          ...formData,
          logradouro: response.data.logradouro,
          bairro: response.data.bairro,
          cidade: response.data.localidade,
          estado: response.data.uf,
        });
      } catch (error) {
        console.error('Erro ao buscar o CEP', error);
      }
    }
  };

  const getCpfCnpjMask = (value) => {
    // Remove todos os caracteres não numéricos
    const numericValue = value.replace(/\D/g, '');
  
    // Se tiver 11 dígitos ou menos, usar máscara de CPF, caso contrário, CNPJ
    return numericValue.length <= 11 ? '999.999.999-99' : '99.999.999/9999-99';
  };

  return (
    <div className={styles.container}>
      <div className={styles.augustofoto02}>
        <img src={bg} alt='augusto' />
      </div>
      <div className={styles.containerFormulario}>
        <div className={styles.steps}>
          <Steps current={currentStep} direction='horizontal'>
            {Array.from({ length: 6 }, (_, i) => ({ title: '' })).map((item, index) => (
              <Step key={index} />
            ))}
          </Steps>
        </div>

        {success ? (
          <div className={styles.successMessage}>
            <h2>Cadastro realizado com sucesso!</h2>
            <p>Entraremos em contato em breve.</p>
          </div>
        ) : (
          <Form form={form} layout='vertical' autoComplete='off' style={{ width: '100%' }}>
            <div className={styles.stepsContent}>
              {currentStep === 0 && (
                <>
                  <h2 className={styles.titleFormulario}>Preencha o formulário para receber sua consultoria personalizada</h2>
                  <Form.Item className={styles.formInput} label='E-mail' name='email' rules={[{ required: true, message: 'Por favor, insira seu e-mail' }]}>
                    <Input onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                  </Form.Item>
                </>
              )}

              {currentStep === 1 && (
                <>
                  <h2 className={styles.titleFormulario}>Prezado(a), é de sua preferência o atendimento?</h2>
                  <Radio.Group onChange={onChangeValue} value={formData.isPresencial ? 'presencial' : 'telepresencial'} className={styles.containerCheckbox}>
                    <Radio value='presencial'>Presencial, no escritório.</Radio>
                    <Radio value='telepresencial'>Telepresencial, por videoconferência.</Radio>
                  </Radio.Group>
                </>
              )}
              {currentStep === 2 && (
                <div className={styles.conteudoTexto}>
                  <p>Valor da consulta presencial ou telepresencial: R$ 2.400,00 (Dois mil e quatrocentos reais), com até 1 hora de atendimento.</p>
                  <p>Ultrapassado a hora de atendimento, será cobrado o valor de mais uma hora adicional, não sendo cobrado o valor proporcional e sim hora cheia.</p>
                  <p>Posterior contato por e-mail para envio de relatório e atendimento de eventuais dúvidas.</p>
                  <div className={styles.containerBancario}>
                    <h2>Dados bancários:</h2>
                    <h2>Modalidade depósito bancário</h2>
                    <h2>Banco: Itaú</h2>
                    <h2>AG: 0049</h2>
                    <h2>C/C: 27.336-0</h2>
                    <h2>Favorecido: Fauvel Moraes Sociedade Advogados</h2>
                    <h2> CNPJ: 09.514.034/0001-79 </h2>
                    <div className={styles.containerPix}>
                      <h2>Chave Pix: 09.514.034/0001-79</h2>
                      <Button onClick={handleCopy} icon={<IoMdCopy style={{ fontSize: '20px' }} />} className={styles.botaoPix}></Button>
                    </div>
                  </div>

                  <p>Após o envio do comprovante será confirmado a data e o horário e/ou enviado o link de acesso.</p>
                  <p>
                    Sobre o cancelamento ou não comparecimento na data e horário agendados:
                    <br />
                    Em caso de cancelamento da consulta antes de 24h da data agendada ou de não comparecimento na data e horário previamente agendados, não será possível a devolução do valor pago.
                  </p>
                  <h3>Cancelamento antes de 24h da data agendada, poderá ser remarcado.</h3>
                </div>
              )}
              {currentStep === 3 && (
                <>
                  <h2 className={styles.titleFormulario}>Informe, por gentileza, seus dados.</h2>

                  <Form.Item className={styles.formInput} label='Informe seu nome completo' name='nomeCliente' rules={[{ required: true, message: 'Por favor, insira seu nome completo' }]}>
                    <Input onChange={(e) => setFormData({ ...formData, nomeCliente: e.target.value })} />
                  </Form.Item>
                  <Form.Item className={styles.formInput} label='Telefone(s) para contato' name='telefone' rules={[{ required: true, message: 'Por favor, insira seu telefone' }]}>
                    <InputMask mask='(99) 99999-9999' maskChar={null} value={formData.telefone} onChange={(e) => setFormData({ ...formData, telefone: e.target.value })}>
                      {(inputProps) => <Input {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                  <Form.Item
                    className={styles.formInput}
                    label='CPF/CNPJ'
                    name='cpfCnpj'
                    rules={[{ required: true, message: 'Por favor, insira seu CPF ou CNPJ' }]}
                  >
                    <InputMask
                      mask={getCpfCnpjMask(formData.cpfCnpj)}
                      maskChar={null}
                      value={formData.cpfCnpj}
                      onChange={(e) => setFormData({ ...formData, cpfCnpj: e.target.value })}
                    >
                      {(inputProps) => <Input {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                </>
              )}
              {currentStep === 4 && (
                <div>
                  <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item className={styles.formInput} label='CEP ' name='cep'>
                        <Input value={formData.cep} onChange={handleCepChange} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item className={styles.formInput} label='Estado '>
                        <Input value={formData.estado} onChange={(e) => setFormData({ ...formData, estado: e.target.value })} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item className={styles.formInput} label='Logradouro '>
                        <Input value={formData.logradouro} onChange={(e) => setFormData({ ...formData, logradouro: e.target.value })} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item className={styles.formInput} label='Número '>
                        <Input value={formData.numero} onChange={(e) => setFormData({ ...formData, numero: e.target.value })} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item className={styles.formInput} label='Bairro '>
                        <Input value={formData.bairro} onChange={(e) => setFormData({ ...formData, bairro: e.target.value })} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item className={styles.formInput} label='Cidade '>
                        <Input value={formData.cidade} onChange={(e) => setFormData({ ...formData, cidade: e.target.value })} />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              )}

              {currentStep === 5 && (
                <>
                  <h2 className={styles.titleFormulario}>Por gentileza, fazer a seguir uma breve descrição da situação, podendo também anexar documentos, caso queira.</h2>

                  <Form.Item label='Informe o assunto da consulta, fazendo uma breve descrição:' name='assuntoConsulta' rules={[{ required: true, message: 'Por favor, insira o assunto da consulta' }]}>
                    <Input.TextArea onChange={(e) => setFormData({ ...formData, assuntoConsulta: e.target.value })} />
                  </Form.Item>
                  <Form.Item label='Aqui, você pode anexar seus documentos:'>
                    <Dragger {...props}>
                      <p className='ant-upload-drag-icon'>
                        <GoPlus style={{ fontSize: '36px', padding: '.5rem', borderRadius: '3.125rem', border: '1px dashed  #003587', color: '#003587' }} />
                      </p>
                      <p className='ant-upload-text'>Selecione o arquivo </p>
                      <p className='ant-upload-hint'>Procure o arquivo em seu dispositivo</p>
                    </Dragger>
                  </Form.Item>
                </>
              )}
            </div>

            <div className='steps-action'>
              {currentStep < 5 && (
                <div className={styles.buttons}>
                  {currentStep > 0 && (
                    <Button className={styles.buttonback} onClick={prevStep} icon={<IoChevronBack style={{ fontSize: '1.5rem' }} />}>
                      Voltar
                    </Button>
                  )}
                  <Button className={styles.buttonNextStep} onClick={nextStep}>
                    Próximo
                  </Button>
                </div>
              )}
              {currentStep === 5 && (
                <Button className={styles.buttonNextStep} onClick={onSubmit} loading={loading}>
                  Enviar
                </Button>
              )}
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export default CadastroCliente;
