import React, { useState } from 'react';
import styles from './style.module.scss';
import logo from '../../assets/logo.png';
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineLock, AiOutlineMail } from "react-icons/ai";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useAuth } from '../../contexts/AuthContext';

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate(); // Initialize useNavigate

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const logged = await login(email, password);
      if (logged) {
        navigate('/dashboard');
        window.location.href = '/dashboard';
        // Redirect to dashboard
        toast.success('Login feito com sucesso!!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      } else {
        toast.warn('Falha ao fazer login, confirme seus dados', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        setLoading(false);
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.loginBox}>
        <img src={logo} style={{ width: '50%' }}></img>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.inputGroup}>
            <label htmlFor='email'>E-mail</label>

            <div className={styles.emailInputContainer}>
            <input type='email' id='email' value={email} onChange={handleEmailChange} required className={styles.passwordInput} />
            <span className={styles.emailIcon}>
                <AiOutlineMail /> {/* Ícone de email */}
              </span>
            </div>

          </div>
          <div className={styles.inputGroup}>
            <label htmlFor='password'>Senha</label>

            <div className={styles.passwordInputContainer}>
              <input
                type={showPassword ? 'text' : 'password'}
                id='password'
                value={password}
                onChange={handlePasswordChange}
                required
                className={styles.passwordInput} // Adicionando uma classe para estilização específica do campo de senha
              />
              <span className={styles.passwordIcon}>
                <AiOutlineLock /> {/* Ícone de cadeado */}
              </span>
              <button
                type='button'
                onClick={togglePasswordVisibility}
                className={styles.showPasswordButton}
              >
                {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </button>
            </div>

          </div>
          <button type='submit' className={styles.loginButton} disabled={loading}>
            {loading ? 'Carregando...' : 'Entrar'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
