import React, { useState, useContext } from 'react';
import styles from './style.module.scss';
import { toast } from 'react-toastify';
import { api } from '../../services/apiClient';
import { Button, Col, Row } from 'antd';
import logo from '../../assets/logo.png';
import king from '../../assets/king.png';
import { Link } from 'react-router-dom';

const Home = () => {
  const [loading, setLoading] = useState(false);

  const [cnpj, setCnpj] = useState('');

  const handleSubmit = async (e) => {
    setLoading(true);

    if (cnpj === null || cnpj === undefined || cnpj === '') {
      toast.warn(`O campo CNPJ é inválido!`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setLoading(false);
      return;
    }

    const cnpjUnformatted = cnpj.replace(/\D/g, '');

    const response = await api.get(`/cliente-redirect/${cnpjUnformatted}`);
    if (response.status === 200 && response.data) {
      if (response.data.siteDestino) {
        window.location.href = response.data.siteDestino;
      } else {
        toast.warn(`Não foi possível localizar seu cadastro por gentileza entre em contato com o suporte!`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    } else {
      toast.warn(`Não foi possível localizar seu cadastro por gentileza entre em contato com o suporte!`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className={styles.container}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className={styles.containerTexto}>
            <img src={logo} alt='logo' />
            <h2>Seja bem-vindo à Fauvel Moraes.</h2>
            <p className={styles.text01}>
              Com mais de 22 anos de experiência, nossa equipe especializada está aqui para fornecer suporte jurídico de excelência, focado em soluções eficazes e estratégias que garantam conformidade legal. Nosso compromisso é agir com agilidade e
              eficiência, buscando sempre o sucesso dos seus objetivos.
            </p>
            <p className={styles.text02}>Conte conosco para uma parceria construtiva e próspera, oferecendo segurança jurídica e facilitando o entendimento legal através do nosso programa de compliance.</p>
            <p className={styles.text03}>Estamos ansiosos para discutir suas demandas e responder a quaisquer dúvidas. Obrigado por escolher a Fauvel Moraes como sua parceira de confiança. Vamos juntos alcançar resultados excepcionais.</p>
            <Link to='/cadastro'>
              <Button className={styles.button}>Entre em contato</Button>
            </Link>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className={styles.containerImagemHome}>
            <img src={king} alt='rei' />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
